import React from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
// import { useSpeechSynthesis } from 'react-speech-kit';
import ChatMessage from "../components/chatMessages";

const DallE = () => {
  const divRef = useRef(null);
  const [chatLog, setChatLog] = useState([]);
  const [input, setInput] = useState("");
  const [currentModel, setCurrentModel] = useState("image");
  const [txtHeight, setTxtHeight] = useState(50);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  // const { speak, speaking } = useSpeechSynthesis();

  function clearChat() {
    setChatLog([]);
  }

  const handleSpeechChange = (input) => {
    setInput(input);
    const lines = input.split("\n").length;
    if (lines < txtHeight / 25) {
      if (txtHeight !== 50) {
        setTxtHeight(50 + (lines - 1) * 25);
        console.log("ht", 50 + (lines - 1) * 25);
        console.log("here");
      }
    }
  };

  // const speakGptMsg = (msg) => {
  //   speak({ text: msg });
  // };

  if(transcript != "" && transcript != input){
    console.log('listening');
    handleSpeechChange(transcript);
  }

  if(!listening && transcript != ""){
      console.log('listening stopped');
      resetTranscript();
  }

  async function handleSubmit(e) {
    // e.preventDefault();
    let chatLogNew = [...chatLog, { user: "me", message: `${input}` }];
    setChatLog(chatLogNew);
    let messages = input;
    setInput("");
    console.log("chatlognew", chatLogNew);
    console.log("message", messages);
    const response = await fetch("http://chatbot-app-v3.stagingwebsites.info:3080/", {
    // const response = await fetch("http://localhost:3000/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: messages,
        currentModel,
      }),
    });
    const data = await response.json();
    console.log(data);
    setChatLog([
      ...chatLogNew,
      { user: "gpt", image: data.image, message: data.message },
    ]);
    // speakGptMsg(data.message);
    console.log({ user: "gpt", message: data.message });
  }

  const handleChange = (e) => {
    setInput(e.target.value);
    const lines = e.target.value.split("\n").length;
    if (lines < txtHeight / 25) {
      if (txtHeight !== 50) {
        setTxtHeight(50 + (lines - 1) * 25);
        console.log("ht", 50 + (lines - 1) * 25);
        console.log("here");
      }
    }
  };

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("text");
    const lines = pastedText.split("\n").length;
    // setInput(input + pastedText);
    setTxtHeight(txtHeight + (lines - 1) * 25);
  };

  const setHeight = (event) => {
    if (event.shiftKey && event.keyCode === 13) {
      // setInput(input + "\n");
      setTxtHeight(txtHeight + 25);
    } else if (event.keyCode === 13) {
      event.preventDefault();
      if (input === "") return false;
      handleSubmit();
      setTxtHeight(50);
    }
  };

  return (
    <div className="App">
      {/* This is where we have defined the sidebar */}

      <aside className="sidemenu">
        <h1>Aside</h1>
        <div className="side-menu-button" onClick={clearChat}>
          <span>+</span>New Chat
        </div>
        <div className="models">
          {/* <select
            className="form-select mt-3"
            onChange={(e) => setCurrentModel(e.target.value)}
            value={currentModel}
          >
            {models.map((model, index) => (
              <option
                key={model}
                value={model}
                // selected={model === "text-davinci-003" && "selected"}
              >
                {model}
              </option>
            ))}
            <option value="image">Image</option>
          </select> */}
        </div>
      </aside>

      {/* This is where we have defined the other half chat window of the display */}

      <section className="chatbox">
        <div className="chatbox-text-area">
          <h2>
            Welcome to Vikas Dall-E (React+Node) application. Try{" "}
            <Link to="/">ChatGPT.</Link>
          </h2>
        </div>
        <div ref={divRef} className="chat-box-container">
          <div className="chat-log">
            {chatLog.map((message, index) => (
              <ChatMessage key={index} message={message} />
            ))}

            <div className="chat-input-holder">
              <div className="speech-to-text-container">
                {listening ? <img
                              onClick={SpeechRecognition.stopListening}
                              src={process.env.PUBLIC_URL + "/mic-on.gif"}
                              alt="mic-on"
                              className="mic-img"
                            /> : <img
                              onClick={SpeechRecognition.startListening}
                              src={process.env.PUBLIC_URL + "/mic-off.png"}
                              alt="mic-on"
                              className="mic-img"
                            />}

              </div>
              <form onSubmit={handleSubmit}>
                <textarea
                  style={{ height: `${txtHeight}px` }}
                  rows="1"
                  value={input}
                  // onChange={(e) => setInput(e.target.value)}
                  onChange={handleChange}
                  onKeyDown={setHeight}
                  onPaste={handlePaste}
                  className="chat-input-textarea form-text"
                  placeholder="Type Your Message Here"
                ></textarea>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DallE;
