import logo from "./logo.svg";
import "./App.css";
import "./normalize.css";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import Dictaphone from './components/speechToText';
import React from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useSpeechSynthesis } from 'react-speech-kit';

function App() {
  useEffect(() => {
    getEngines();
  }, []);

  const divRef = useRef(null);
  const [input, setInput] = useState("");
  const [models, setModels] = useState([]);
  const [currentModel, setCurrentModel] = useState("text-davinci-003");
  const [chatLog, setChatLog] = useState([]);
  const [txtHeight, setTxtHeight] = useState(50);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const { speak, speaking } = useSpeechSynthesis();

  useEffect(() => {
    divRef.current.scrollTop = divRef.current.scrollHeight;
  }, [chatLog]);
  function clearChat() {
    setChatLog([]);
  }

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("text");
    const lines = pastedText.split("\n").length;
    // setInput(input + pastedText);
    setTxtHeight(txtHeight + (lines - 1) * 25);
  };

  const setHeight = (event) => {
    if (event.shiftKey && event.keyCode === 13) {
      // setInput(input + "\n");
      setTxtHeight(txtHeight + 25);
    } else if (event.keyCode === 13) {
      event.preventDefault();
      if (input === "") return false;
      handleSubmit();
      setTxtHeight(50);
    }
  };


  const handleChange = (e) => {
    // console.log('hello');
    // setInput("");
    setInput(e.target.value);
    const lines = e.target.value.split("\n").length;
    if (lines < txtHeight / 25) {
      if (txtHeight !== 50) {
        setTxtHeight(50 + (lines - 1) * 25);
        console.log("ht", 50 + (lines - 1) * 25);
        console.log("here");
      }
    }
  };

  const handleSpeechChange = (input) => {
    setInput(input);
    const lines = input.split("\n").length;
    if (lines < txtHeight / 25) {
      if (txtHeight !== 50) {
        setTxtHeight(50 + (lines - 1) * 25);
        console.log("ht", 50 + (lines - 1) * 25);
        console.log("here");
      }
    }
  };

  const speakGptMsg = (msg) => {
    speak({ text: msg });
  };

  if(transcript != "" && transcript != input){
    console.log('listening');
    handleSpeechChange(transcript);
  }

  if(!listening && transcript != ""){
      console.log('listening stopped');
      resetTranscript();
  }

  function getEngines() {
    fetch("http://chatbot-app-v3.stagingwebsites.info:3080/models")
      .then((res) => res.json())
      .then((data) => {
        let ids = data.models.map((model) => {
          return model.id;
        });
        setModels(ids);
  });

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesnt support speech recognition</span>;
  }
}

  async function handleSubmit(e) {
    // e.preventDefault();
    let chatLogNew = [...chatLog, { user: "me", message: `${input}` }];
    setChatLog(chatLogNew);
    let messages = chatLogNew.map((message) => message.message).join("\n");
    setInput("");
    console.log("chatlognew", chatLogNew);
    console.log("message", messages);
    const response = await fetch(
      "http://chatbot-app-v3.stagingwebsites.info:3080/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: messages,
          currentModel,
        }),
      }
    );
    const data = await response.json();
    console.log(data);
    setChatLog([
      ...chatLogNew,
      { user: "gpt", image: data.image, message: data.message },
    ]);
    speakGptMsg(data.message);
    console.log({ user: "gpt", message: data.message });
  }

  return (
    <div className="App">
      {/* This is where we have defined the sidebar */}

      <aside className="sidemenu">
        <h1>Aside</h1>
        <div className="side-menu-button" onClick={clearChat}>
          <span>+</span>New Chat
        </div>
        <div className="models">
          <select
            className="form-select mt-3"
            onChange={(e) => setCurrentModel(e.target.value)}
            value={currentModel}
          >
            {models.map((model, index) => (
              <option
                key={model}
                value={model}
                // selected={model === "text-davinci-003" && "selected"}
              >
                {model}
              </option>
            ))}
          </select>
        </div>
      </aside>

      {/* This is where we have defined the other half chat window of the display */}

      <section className="chatbox">
        <div className="chatbox-text-area">
          <h2>
            Welcome to Vikas ChatGPT (React+Node) application. Try{" "}
            <Link to="/dall-e">Dall-E.</Link>
          </h2>
        </div>
        <div ref={divRef} className="chat-box-container">
          <div className="chat-log">
            {chatLog.map((message, index) => (
              <ChatMessage key={index} message={message} />
            ))}

            <div className="chat-input-holder">
              <div className="speech-to-text-container">
                {listening ? <img
                              onClick={SpeechRecognition.stopListening}
                              src={process.env.PUBLIC_URL + "/mic-on.gif"}
                              alt="mic-on"
                              className="mic-img"
                            /> : <img
                              onClick={SpeechRecognition.startListening}
                              src={process.env.PUBLIC_URL + "/mic-off.png"}
                              alt="mic-on"
                              className="mic-img"
                            />}

              </div>
              <form onSubmit={handleSubmit}>
                <textarea
                  style={{ height: `${txtHeight}px` }}
                  rows="1"
                  value={input}
                  // value={transcript ?? input}
                  // onChange={(e) => setInput(e.target.value)}
                  onChange={handleChange}
                  onKeyDown={setHeight}
                  onPaste={handlePaste}
                  className="chat-input-textarea form-text"
                  placeholder="Type Your Message Here"
                ></textarea>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const ChatMessage = ({ message }) => {
  const { speak, speaking } = useSpeechSynthesis();
  
  return (
    <div
      className={`chat-message ${
        message.user === "gpt" ? "chatgpt" : "chat-user"
      }`}
    >
      <div className="chat-message-center ms-4">
        <div
          className={`avatar border ${
            message.user === "gpt" ? "chatgpt" : "user"
          }`}
        >
          {message.user === "gpt" ? (
            <svg
              width={40}
              height={40}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              strokeWidth={1.5}
              className="h-6 w-6"
            >
              <path
                d="M37.532 16.87a9.963 9.963 0 0 0-.856-8.184 10.078 10.078 0 0 0-10.855-4.835A9.964 9.964 0 0 0 18.306.5a10.079 10.079 0 0 0-9.614 6.977 9.967 9.967 0 0 0-6.664 4.834 10.08 10.08 0 0 0 1.24 11.817 9.965 9.965 0 0 0 .856 8.185 10.079 10.079 0 0 0 10.855 4.835 9.965 9.965 0 0 0 7.516 3.35 10.078 10.078 0 0 0 9.617-6.981 9.967 9.967 0 0 0 6.663-4.834 10.079 10.079 0 0 0-1.243-11.813ZM22.498 37.886a7.474 7.474 0 0 1-4.799-1.735c.061-.033.168-.091.237-.134l7.964-4.6a1.294 1.294 0 0 0 .655-1.134V19.054l3.366 1.944a.12.12 0 0 1 .066.092v9.299a7.505 7.505 0 0 1-7.49 7.496ZM6.392 31.006a7.471 7.471 0 0 1-.894-5.023c.06.036.162.099.237.141l7.964 4.6a1.297 1.297 0 0 0 1.308 0l9.724-5.614v3.888a.12.12 0 0 1-.048.103l-8.051 4.649a7.504 7.504 0 0 1-10.24-2.744ZM4.297 13.62A7.469 7.469 0 0 1 8.2 10.333c0 .068-.004.19-.004.274v9.201a1.294 1.294 0 0 0 .654 1.132l9.723 5.614-3.366 1.944a.12.12 0 0 1-.114.01L7.04 23.856a7.504 7.504 0 0 1-2.743-10.237Zm27.658 6.437-9.724-5.615 3.367-1.943a.121.121 0 0 1 .113-.01l8.052 4.648a7.498 7.498 0 0 1-1.158 13.528v-9.476a1.293 1.293 0 0 0-.65-1.132Zm3.35-5.043c-.059-.037-.162-.099-.236-.141l-7.965-4.6a1.298 1.298 0 0 0-1.308 0l-9.723 5.614v-3.888a.12.12 0 0 1 .048-.103l8.05-4.645a7.497 7.497 0 0 1 11.135 7.763Zm-21.063 6.929-3.367-1.944a.12.12 0 0 1-.065-.092v-9.299a7.497 7.497 0 0 1 12.293-5.756 6.94 6.94 0 0 0-.236.134l-7.965 4.6a1.294 1.294 0 0 0-.654 1.132l-.006 11.225Zm1.829-3.943 4.33-2.501 4.332 2.5v5l-4.331 2.5-4.331-2.5V18Z"
                fill="currentColor"
              />
            </svg>
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/user_icon.svg"}
              alt="user"
              className="user-img"
            />
          )}
        </div>
        {message.image ? (
          <div className="message">
            <div className="row">
              {message.message.map((img, index) => {
                return (
                  <div key={index} className="col">
                    <div
                      className="card"
                      style={{ width: "16rem", backgroundColor: "#444654" }}
                    >
                      <img
                        src={img.url}
                        style={{ borderRadius: "6px" }}
                        className="card-img-top"
                        alt="..."
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="message">{message.message}</div>
        )}
        {/* <div className="message">{message.message}</div> */}
        <div className="text-to-speech-container">
          {speaking ? <img
                    src={process.env.PUBLIC_URL + "/speaker-on.png"}
                    alt="speaker-on"
                    className="speaker-img"
                  /> : <img
                    onClick={() => speak({ text: message.message })}
                    src={process.env.PUBLIC_URL + "/speaker-off.png"}
                    alt="speaker-on"
                    className="speaker-img"
                  />}
        </div>
      </div>
    </div>
  );
};

export default App;
